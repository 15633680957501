import axios from '@/libs/axios'

class Account {
  static dashboard() {
    return axios.post('user/dashboard')
  }

  static me() {
    return axios.post('user/me')
  }

  static memberList(params) {
    return axios.post('user/members', params)
  }

  static getCompanyList(params) {
    return axios.post('user/companies', params)
  }

  static accountList(params) {
    return axios.post('user/accounts', params)
  }

  static subAccountList(params) {
    return axios.post('user/sub-accounts', params)
  }

  static transfer(params) {
    return axios.post('user/transaction/transfer', params)
  }

  static store(params) {
    return axios.post('user/account/register', params)
  }

  static changeMyPassword(params) {
    return axios.post('user/account/change-my-password', params)
  }

  static storeSubAccount(params) {
    return axios.post('user/sub-accounts/register', params)
  }

  static edit(params) {
    return axios.post('user/account', params)
  }

  static update(params) {
    return axios.post('user/account/update', params)
  }

  static editSubAccount(params) {
    return axios.post('user/sub-account', params)
  }

  static updateSubAccount(params) {
    return axios.post('user/sub-account/update', params)
  }

  static changeStatus(params) {
    return axios.post('user/account/change-status', params)
  }

  static changeTranscode(params) {
    return axios.post('user/account/change-transcode', params)
  }

  static clearSession(params) {
    return axios.post('user/account/clear-session', params)
  }

  static banList(params) {
    return axios.post('user/account/ban-list', params)
  }

  static unban(params) {
    return axios.post('user/account/unban', params)
  }

  static forgotPassword(params) {
    return axios.post('auth/admin/forgot-password', params)
  }

  static validateToken(params) {
    return axios.post('auth/admin/forgot-password/validate-token', params)
  }

  static changePassword(params) {
    const config = {
      headers: { Authorization: `Bearer ${params.accessToken}` },
    }
    const bodyParameters = {
      password: params.password,
      email: params.email,
      type: params.type,
    }
    return axios.post('auth/admin/change-password', bodyParameters, config)
  }

  static balanceList(params) {
    return axios.post('user/account/balance', params)
  }

  static getGameLimits(params) {
    return axios.get('user/account/game-limits', params)
  }

  static updateGameLimit(params) {
    return axios.post('user/account/game-limits', params)
  }
}

export default Account
